<template>
  <div class="swiper-container mainSlide">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/slide-1.png">
        </a>
      </div>
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/slide-2.png">
        </a>
      </div>
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/slide-3.png">
        </a>
      </div>
      <div class="swiper-slide">
        <a>
          <img src="../../assets/img/main/slide-4.png">
        </a>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'UiSwiper',
  title: 'Navigation',
  data () {
    return {
      bannerList: [
      ]
    }
  },
  mounted () {
    this.loadSwiper()
  },
  watch: {
    banner () {
      if (this.banner) {
        this.loadSwiper()
      }
    }
  },
  computed: {
    ...mapState([
      'banner'
    ])
  },
  methods: {
    loadSwiper () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container', {
        spaceBetween: 30,
        autoplay: { // 자동 슬라이드 설정 , 비 활성화 시 false
          delay: 4000, // 시간 설정
          disableOnInteraction: false // false로 설정하면 스와이프 후 자동 재생이 비활성화 되지 않음
        },
        loop: true,
        observer: true,
        observeParents: true,

        // If we need pagination
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })

      if (this.banner) {
        setTimeout(function () {
          this.$emit('loaded', true)
        }.bind(this), 500)
      }
    }
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>
.mainSlide {padding-top: 20px;}
.swiper-container {
  z-index: 20;
}
.swiper-pagination-bullet {display: none;}
.s_ment {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #fff;width: 80%;max-width: 1314px;margin: 0 auto;}
.s_ment h2 {font-size: 12pt;color: #e5972d;margin-bottom: 15px;}
.s_ment h1 {font-size: 20pt;margin-bottom: 20px;}
.s_ment h3 {font-size: 12pt;}
</style>
